import React from "react";
// import { StaticImage } from "gatsby-plugin-image"
import "./Affiliates.scss";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import {BrowserView, MobileView} from 'react-device-detect';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Batangueno from "../../../images/affiliates/batangueno.png";

const Affiliates = () => {
  const data = useStaticQuery(graphql`
    query {
      starTerminal: file(relativePath: { eq: "star-terminal.png" }) {
        childImageSharp {
          fixed(width: 70, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      batangueno: file(relativePath: { eq: "batangueno.png" }) {
        childImageSharp {
          fixed(height: 70, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      starc: file(relativePath: { eq: "starc.png" }) {
        childImageSharp {
          fixed(height: 100, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      hansenAndEduard: file(relativePath: { eq: "hansen-and-eduard.png" }) {
        childImageSharp {
          fixed(height: 40, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      bintakay: file(relativePath: { eq: "bintakay.png" }) {
        childImageSharp {
          fixed(height: 80, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      fuelTanker: file(relativePath: { eq: "fueltanker.png" }) {
        childImageSharp {
          fixed(height: 80, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      aesi: file(relativePath: { eq: "aesi.png" }) {
        childImageSharp {
          fixed(height: 40, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    }
  };

  return (
    <>
      <div className="affiliates">
        <p className="title">Our Groups</p>
        <div className="af-img-web">
         <BrowserView>
         <div className="af-imgs">
              <div className="af-con">
                <Img
                  fixed={data.starTerminal.childImageSharp.fixed}
                  alt="star terminal"
                />
              </div>
              <div className="af-con">
                <Img
                  fixed={data.batangueno.childImageSharp.fixed}
                  alt="batangueno"
                />
              </div>

              <div className="af-con">
                <Img
                  fixed={data.hansenAndEduard.childImageSharp.fixed}
                  alt="hansen and eduard"
                />
              </div>
              <div className="af-con">
                <Img
                  fixed={data.bintakay.childImageSharp.fixed}
                  alt="bintakay"
                />
              </div>

              <div className="af-con">
                <Img
                  fixed={data.fuelTanker.childImageSharp.fixed}
                  alt="fuelTanker"
                />
              </div>

              <div className="af-con">
                <Img fixed={data.aesi.childImageSharp.fixed} alt="aesi" />
              </div>
            </div>

            <a href="/our-footprint"><button>LEARN MORE</button></a>
         </BrowserView>

        </div>

       <div style={{ position: "relative" }}>
       <MobileView className="group-carousel">
          <Carousel 
             responsive={responsive}
            showDots={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            autoPlay={false}
            autoPlaySpeed={10000}
            // itemClass="m-services"
            ssr={true}
            infinite={true}
            //  swipeable={false}
            draggable={false}
            >
              {/* <img src={Batangueno} alt="Batangueno" height={70} /> */}
              <Img
                    fixed={data.batangueno.childImageSharp.fixed}
                    alt="batangueno"
              />
              <Img
                fixed={data.starTerminal.childImageSharp.fixed}
                alt="star terminal"
              />
              <Img fixed={data.bintakay.childImageSharp.fixed} alt="bintakay" />
              <Img
                fixed={data.fuelTanker.childImageSharp.fixed}
                alt="fuelTanker"
              />
              <Img
                fixed={data.hansenAndEduard.childImageSharp.fixed}
                alt="hansen and eduard"
              />
               <Img fixed={data.aesi.childImageSharp.fixed} alt="aesi" />
          </Carousel>
       </MobileView>
       </div>
      </div>
    </>
  );
};

export default Affiliates;
