import React, { useEffect } from "react";
import "./AboutUs.scss";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
// import Slide from "react-reveal/Slide";
import {BrowserView, MobileView} from 'react-device-detect';

import Star from "../../../images/star-logo-2.png"

import Rellax from "rellax";

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutUs: file(relativePath: { eq: "star-logo-2.png" }) {
        childImageSharp {
          fixed(width: 180, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  // useEffect(() => {
  //   new Rellax(".ab-desc", {
  //     speed: -2,
  //     center: false,
  //     wrapper: null,
  //     round: true,
  //     vertical: true,
  //     horizontal: false
  //   });
  // }, []);

  return (
    <div className="about-us">
      <div className="skewed-bg"></div>
      <section>
        <div className="flex-70 ab-desc">
          <div className="ab-title">
            <MobileView className="mob-star">
              <img style={{ padding: "2.5em 1em 0 0" }} src={Star} width={110} alt="STAR" />
             </MobileView>
             <div>
              <p className="about">S.T.A.R.</p>
              <p className="us">SERVICES GROUP</p>
             </div>
            </div>
          <div className="ab-text">
          The STAR Services group is a cluster of companies with Macro-level 
          Supply Chain Logistics as its Core Competence. It was pioneered in 
          the 80's by Mega Equipment Intl. Corp. which used to Operate in Manila bay 
          and then later evolved to what it is today with Operations in various provinces.
          </div>
          <a href="/history"><button>LEARN MORE</button></a>
        </div>
        <div className="flex-30 ab-image">
         <BrowserView>
         <div className="cust-border"></div>
            <div className="star-logo">
              {/* <Img
                fixed={data.aboutUs.childImageSharp.fixed}
                alt="star-terminal"
              /> */}
              <img src={Star} width={120} alt="star" />
            </div>
         </BrowserView>
         <MobileView>
         <div className="star-logo">
              <Img
                fixed={data.aboutUs.childImageSharp.fixed}
                alt="star-terminal"
              />
            </div>
         </MobileView>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
