import React, { useState, useEffect } from "react";
import "./OurServices.scss";
import classnames from "classnames";
import {BrowserView, MobileView} from 'react-device-detect';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// IMAGES
import Steve1 from "../../../images/cranes.png";
import Steve2 from "../../../images/stevedoring.png";
import Load1 from "../../../images/cargo-truck.jpeg";
import Load2 from "../../../images/loading-truck.png";
import Store1 from "../../../images/storage-2.png";
import Cargo1 from "../../../images/cargo-ship-2.jpeg";
import Port1 from "../../../images/port-terminal.png";
import Cons1 from "../../../images/construction.png";
import IT1 from "../../../images/coding.jpeg";

const OurServices = ({ data }) => {
  const [stop, setStop] = useState(false);
  
  const [select, setSelected] = useState(0);
  const [display, setDisplayed] = useState(false);
  const [img, setImg] = useState(null);
  const [imgCount, setImgCount] = useState(0);

  const services = [
    {
      id: 1,
      name: "Stevedoring & Arrastre",
      defaultImg: Steve1,
      imgs: [Steve1, Steve2],
      link: "/stevedoring-and-arrastre",
      desc: "With over 30 years experience in the handling of bulk cargo, the S.T.A.R. Group's methods not only ensures efficient"
    },
    {
      id: 2,
      name: "Loading",
      defaultImg: Load1,
      imgs: [Load1, Load2],
      link: "/loading",
      desc: "The S.T.A.R. Group's  loading services ensures the efficient and effective loading of cargo unto the client's trucks",
    },
    {
      id: 3,
      name: "Transit Storage",
      defaultImg: Store1,
      imgs: [Load1, Load2],
      link: "/transit-storage",
      desc: "Our Group's services, with proper coordination with our Cosnignees, ensure that consignees have ample storage",
    },
    {
      id: 4,
      name: "Cargo Delivery",
      defaultImg: Cargo1,
      imgs: [Load1, Load2],
      link: "/cargo-delivery",
      desc: "",
    },
    {
      id: 5,
      name: "Terminal Management",
      defaultImg: Port1,
      imgs: [Load1, Load2],
      link: "/terminal-management",
      desc: "We make sure that any facility that we engage is able to live up to its utmost potential.",
    },
    // {
    //   id: 6,
    //   name: "Port Facility Development",
    //   defaultImg: Cons1,
    //   imgs: [Load1, Load2],
    //   link: "/construction",
    //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
    // },
    {
      id: 6,
      name: "IT Services",
      defaultImg: IT1,
      imgs: [Load1, Load2],
      link: "/it-services",
      desc: "Our Integrated System, managed by our IT partner - AESi Automation Solutions by Accur8 Enterprise Solutions Inc",
    },
  ]

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <>
      <div className="our-services d-flex flex-column">
        <div className="d-flex flex-row fWrap w100p layout-margin">
         
        <BrowserView className="d-flex flex-row fWrap w100p layout-margin">
          <div className="flex-25 os-title w100p">
              <div className="bg"></div>

              <div className="titlez">
                <div className="text">
                  <p>OUR</p>
                  <p>SERVICES</p>
                </div>
                <span className="desc">
                The STAR Group's Integrated service revolve 
                around Supply Chain Bulk and Break Bulk Cargo 
                Logisitcs supported by tangible and intangible 
                Infrastructure.
                </span>
              </div>
          </div>
        { services.map((serv, i) =>
          <div 
            className="flex-25"
            key={i}
            onMouseEnter={e => {
              e.preventDefault();
              setSelected(serv.id);
            }}
            onMouseLeave={e => {
              e.preventDefault();
              setSelected(0);
            }}
            >
              <img src={serv.id ? 
              serv.defaultImg : serv.imgs[1]} 
              alt={serv.name} className={classnames({
                "w100p": true,
                "darkImg": select !== serv.id,
                "scale": select === serv.id
              })}/>

              <div className="service-text-con">
                <div className={
                  classnames({
                    "service-text": true,
                    "add-border": select === serv.id,
                  })}>
                  <p>{serv.name}</p>

                  { select === serv.id ? <a href={serv.link}><button>LEARN MORE</button></a> : "" }
                </div>
              </div>
          </div>
         )}
        </BrowserView>
        </div>

        <MobileView className="">
        <div className="flex-25 os-title w100p">
              <div className="bg"></div>

              <div className="title">
                <div className="text">
                  <p>OUR</p>
                  <p>SERVICES</p>
                </div>
                <span className="desc">
                The STAR Group's Integrated service revolve 
                around Supply Chain Bulk and Break Bulk Cargo 
                Logisitcs supported by tangible and intangible 
                Infrastructure.
                </span>
              </div>
          </div>
        <Carousel 
         responsive={responsive}
         showDots={true}
         removeArrowOnDeviceType={["tablet", "mobile"]}
         autoPlay={false}
         autoPlaySpeed={10000}
         itemClass="m-services"
         ssr={true}
         infinite={true}
        //  swipeable={false}
        draggable={false}
         >
            { services.map((serv, i) =>
          <div 
            className="flex-25 d-flex flex-column"
            key={i}
            onMouseEnter={e => {
              e.preventDefault();
              setSelected(serv.id);
            }}
            onMouseLeave={e => {
              e.preventDefault();
              setSelected(0);
            }}
            >
              <img src={serv.id ? 
              serv.defaultImg : serv.imgs[1]} 
              alt={serv.name} className={classnames({
                "w100p": true,
                "darkImg": select !== serv.id,
                "scale": select === serv.id
              })}/>

              <p className="service-name">{serv.name}</p>

              <div className="service-desc">
                {serv.desc}...

                <a href={serv.link}><button>LEARN MORE</button></a>
              </div>

              {/* <div className="service-text-con">
                <div className={
                  classnames({
                    "service-text": true,
                    "add-border": select === serv.id,
                  })}>
                  <p>{serv.name}</p>

                  { select === serv.id ? <a href={serv.link}><button>LEARN MORE</button></a> : "" }
                </div>
              </div> */}
          </div>
         )}
          </Carousel>
        </MobileView>
      </div>
    </>
  );
};

export default OurServices;
