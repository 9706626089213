import React, { useEffect } from "react";
import "./HeroSection.scss";
import PortVid from "../../../images/port-4mb.mp4"
import ReactPlayer from 'react-player/lazy'

const HeroSection = () => {
  // useEffect(() => {
  //   new Rellax(".animate", {
  //     speed: -4,
  //     vertical: true,
  //     horizontal: false
  //   });
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
 }, [])

return (
   <>
      <div className="hero-container home-hero" style={{ pointerEvents: "none" }}>
        <div className="hero-img">
        <ReactPlayer 
          url={PortVid} 
          playing
          loop
          muted
          width="100%"
          height="100%"
          playsinline
        />
        </div>
        
        <div className="home-hero headline animate">
            {/* <div className="hero-border"></div> */}
            <p>Moving</p>
              <p>your</p>
              <p className="yellow">business</p>
              <p>throughout</p>
              <p>the <span className="yellow">Philippine</span></p>
              <p className="yellow">Archipelago.</p>
          {/* <button className="get-started">GET STARTED</button> */}
        </div>
      </div>
    {/* <div className="hero-container">
        <Carousel 
          showThumbs={false} 
          autoPlay={true} 
          interval={3000} 
          infiniteLoop={true} 
          transitionTime={1000}
          // stopOnHover={true}
          // dynamicHeight={true}
        >
            <div>
                <img src={Port} />
                <p className="legend">Moving your products across all borders.</p>
            </div>
            <div>
                <img src={Silo} />
                <p className="legend">Lorem ipsum dolor sit amet.</p>
            </div>
            <div>
                <img src={Crane} />
                <p className="legend">Lorem ipsum dolor sit amet.</p>
            </div>
        </Carousel>
    </div> */}
   </>
  );
};

export default HeroSection;
